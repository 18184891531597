// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Icon5IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Icon5Icon(props: Icon5IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 16 17"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M11.333 5.167H8.667V6.5h2.666c1.1 0 2 .9 2 2s-.9 2-2 2H8.667v1.333h2.666a3.334 3.334 0 000-6.666zm-4 5.333H4.667c-1.1 0-2-.9-2-2s.9-2 2-2h2.666V5.167H4.667a3.335 3.335 0 000 6.666h2.666V10.5zm-2-2.667h5.334v1.334H5.333V7.833z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Icon5Icon;
/* prettier-ignore-end */
