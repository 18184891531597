// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import {
  PlasmicContactUsPage,
  DefaultContactUsPageProps
} from "./plasmic/idea_hub/PlasmicContactUsPage";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as EmailValidator from 'email-validator';
import { sendContactFormToServer } from "../services/firebase_functions";

// Your component props start with props for variants and slots you defined
// in Plasmic, but you can add more here, like event handlers that you can
// attach to named nodes in your component.
//
// If you don't want to expose certain variants or slots as a prop, you can use
// Omit to hide them:
//
// interface ContactUsPageProps extends Omit<DefaultContactUsPageProps, "hideProps1"|"hideProp2"> {
//   // etc.
// }
//
// You can also stop extending from DefaultContactUsPageProps altogether and have
// total control over the props for your component.
export interface ContactUsPageProps extends DefaultContactUsPageProps { }

function ContactUsPage_(
  props: ContactUsPageProps,
  ref: HTMLElementRefOf<"div">
) {
  // Use PlasmicContactUsPage to render this component as it was
  // designed in Plasmic, by activating the appropriate variants,
  // attaching the appropriate event handlers, etc.  You
  // can also install whatever React hooks you need here to manage state or
  // fetch data.
  //
  // Props you can pass into PlasmicContactUsPage are:
  // 1. Variants you want to activate,
  // 2. Contents for slots you want to fill,
  // 3. Overrides for any named node in the component to attach behavior and data,
  // 4. Props to set on the root node.
  //
  // By default, we are just piping all ContactUsPageProps here, but feel free
  // to do whatever works for you.

  const [nameText, setNametext] = React.useState("");
  const [emailText, setEmailText] = React.useState("");
  const [messageText, setMessageText] = React.useState("");

  return <PlasmicContactUsPage root={{ ref }}

    nameTextBox={{
      onChange(event) {
        setNametext(event.target.value);
      }
    }}

    emailTextBox={{
      onChange(event) {
        setEmailText(event.target.value);
      }
    }}

    messageTextBox={{
      onChange(event) {
        setMessageText(event.target.value);
      }
    }}

    toastPlaceHolder={<ToastContainer />}

    submitButton={{
      onClick() {
        if (!nameText) return toast.error("Please input a name.");

        if (!EmailValidator.validate(emailText)) return toast.error("Please make sure your email is valid.");

        if (!messageText) return toast.error("Please input a message.");

        toast.promise(sendContactFormToServer(nameText, emailText, messageText), {
          pending: "Please wait...",
          success: "Thanks! We'll get back to you ASAP",
          error: "Sorry, Something went wrong. Please Try again"
        })

      }
    }}

    {...props} />;
}

const ContactUsPage = React.forwardRef(ContactUsPage_);
export default ContactUsPage;
