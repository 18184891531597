// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import {
  PlasmicResourceModal2,
  DefaultResourceModal2Props
} from "./plasmic/idea_hub/PlasmicResourceModal2";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { ResourceCardModel } from "../model";
import { FacebookIcon, FacebookShareButton, RedditIcon, RedditShareButton, TwitterIcon, TwitterShareButton } from "react-share";
import Button from "./Button";

import "./ResourceModal.css"

// Your component props start with props for variants and slots you defined
// in Plasmic, but you can add more here, like event handlers that you can
// attach to named nodes in your component.
//
// If you don't want to expose certain variants or slots as a prop, you can use
// Omit to hide them:
//
// interface ResourceModal2Props extends Omit<DefaultResourceModal2Props, "hideProps1"|"hideProp2"> {
//   // etc.
// }
//
// You can also stop extending from DefaultResourceModal2Props altogether and have
// total control over the props for your component.
export interface ResourceModal2Props extends DefaultResourceModal2Props {
  resourceCardData: ResourceCardModel;
}

function getSocialMediaMessage(resourceUrl: string): string {
  return "Check out this resource I found on ResourcePal! " + resourceUrl + "\n\n"
    + "Also checkout ResourcePal! The ultimate app for aspiring entrepreneurs to quickly find top-notch products tailored to their startup needs.";
}

function ResourceModal2_(
  { resourceCardData, ...props }: ResourceModal2Props,
  ref: HTMLElementRefOf<"div">
) {
  // Use PlasmicResourceModal2 to render this component as it was
  // designed in Plasmic, by activating the appropriate variants,
  // attaching the appropriate event handlers, etc.  You
  // can also install whatever React hooks you need here to manage state or
  // fetch data.
  //
  // Props you can pass into PlasmicResourceModal2 are:
  // 1. Variants you want to activate,
  // 2. Contents for slots you want to fill,
  // 3. Overrides for any named node in the component to attach behavior and data,
  // 4. Props to set on the root node.
  //
  // By default, we are just piping all ResourceModal2Props here, but feel free
  // to do whatever works for you.

  return <PlasmicResourceModal2 root={{ ref }}

    logoSrc={resourceCardData.logoSrc}
    resourceName={resourceCardData.title}
    resourceLink={<a href={resourceCardData.url} target="_blank"> {resourceCardData.url}</a>}
    visitSite={{
      link: resourceCardData.url,
    }}

    shareButtons={{
      children: [
        <TwitterShareButton
          title={getSocialMediaMessage(resourceCardData.url)} url={window.location.href} style={{ "width": "100%", "maxWidth": "100%" }}
        >
          <Button
            color="blue"
            style={{ "width": "100%" }}>
            <TwitterIcon size={30} round={true} bgStyle={{ "display": "none" }} />
          </Button>
        </TwitterShareButton>,
        <RedditShareButton
          title={getSocialMediaMessage(resourceCardData.url)} url={window.location.href} style={{ "width": "100%", "maxWidth": "100%" }}
        >
          <Button
            color="red"
            style={{ "width": "100%" }}
          >
            <RedditIcon size={30} round={true} bgStyle={{ "display": "none" }} />
          </Button>
        </RedditShareButton>,
        <FacebookShareButton
          title={getSocialMediaMessage(resourceCardData.url)} url={window.location.href} style={{ "width": "100%", "maxWidth": "100%" }}
        >
          <Button
            color="blue"
            style={{ "width": "100%" }}
          >
            <FacebookIcon size={30} round={true} bgStyle={{ "display": "none" }} />
          </Button>
        </FacebookShareButton>

      ]
    }}

    resourceDescription={
      <div className="description" dangerouslySetInnerHTML={{ __html: resourceCardData.bodyText }}></div>}

    {...props} />;
}

const ResourceModal2 = React.forwardRef(ResourceModal2_);
export default ResourceModal2;
