import { getFirestore, collection, setDoc, doc, addDoc } from "firebase/firestore";

import { app, analytics } from "./firebase"

import { logEvent } from "firebase/analytics";

// Initialize db
const db = getFirestore(app);

// Send email to server.
// Returns true if email was sent successfully.
export const sendEmailToServer = async (email) => {
    try {

        const cityRef = await doc(db, 'emails', email);
        await setDoc(cityRef, { email: email, dateAdded: Date() }, { merge: true });
        return true;

    } catch (e) {
        return false;
    }
}

// Send contact form details to server.
// Returns true if email was sent successfully.
export const sendContactFormToServer = async (name, email, message) => {
    try {
        await addDoc(collection(db, "contactUs"), {
            name: name,
            email: email,
            message: message,
            dateAdded: Date()
        })
    } catch (e) {
        return false;
    }
}

// Send new product details to server.
// Returns true if email was sent successfully.
export const sendNewProductFormToServer = async (name, url, keyFeatures, email, pricing, socials) => {
    try {
        await addDoc(collection(db, "newProductRequest"), {
            name: name,
            url: url,
            keyFeatures: keyFeatures,
            email: email,
            pricing: pricing,
            socials: socials,
            dateAdded: Date()
        })
    } catch (e) {
        return false;
    }
}

// Log open resource event.
export const logOpenResourceEvent = (resourceName) => {
    logEvent(analytics, 'select_resource', {
        resourceName: resourceName,
    })
}

// Log open tag event.
export const logOpenTagEvent = (tags) => {
    if (tags === undefined || tags.length == 0) {
        return;
    }
    logEvent(analytics, 'select_tags', {
        tags: tags,
    })
}

// Log subscribe to newsletter event.
export const logSubscribeToNewsletterEvent = () => {
    logEvent(analytics, 'subscribe_to_newsletter')
}