// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import {
  PlasmicResourceCard,
  DefaultResourceCardProps
} from "./plasmic/idea_hub/PlasmicResourceCard";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { ResourceCardModel } from "../model";

// Your component props start with props for variants and slots you defined
// in Plasmic, but you can add more here, like event handlers that you can
// attach to named nodes in your component.
//
// If you don't want to expose certain variants or slots as a prop, you can use
// Omit to hide them:
//
// interface ResourceCardProps extends Omit<DefaultResourceCardProps, "hideProps1"|"hideProp2"> {
//   // etc.
// }
//
// You can also stop extending from DefaultResourceCardProps altogether and have
// total control over the props for your component.
export interface ResourceCardProps extends DefaultResourceCardProps {
  resourceCardData: ResourceCardModel;
  openResourceModal: (currentResourceModal: ResourceCardModel) => void;
}

function ResourceCard_({ resourceCardData, openResourceModal, ...props }: ResourceCardProps, ref: HTMLElementRefOf<"div">) {
  // Use PlasmicResourceCard to render this component as it was
  // designed in Plasmic, by activating the appropriate variants,
  // attaching the appropriate event handlers, etc.  You
  // can also install whatever React hooks you need here to manage state or
  // fetch data.
  //
  // Props you can pass into PlasmicResourceCard are:
  // 1. Variants you want to activate,
  // 2. Contents for slots you want to fill,
  // 3. Overrides for any named node in the component to attach behavior and data,
  // 4. Props to set on the root node.
  //
  // By default, we are just piping all ResourceCardProps here, but feel free
  // to do whatever works for you.

  function tagsToString(tags: string[]): string {
    return tags.map(tag => { return "#" + tag }).join(", ");
  }

  const DEFAULT_BACKGROUND = "#ffff";
  const HOVER_BACKGROUND = "rgba(199, 239, 250, 0.14)";

  const [backgoundColour, setBackGroundColor] = React.useState(DEFAULT_BACKGROUND);

  return <PlasmicResourceCard
    style={{ background: backgoundColour }}

    resourceCard={{ ref }}
    title={resourceCardData.title}
    logoSrc={resourceCardData.logoSrc}

    // TODO: change the slot in plasmic to shorttext.
    bodyText={resourceCardData.shortText}
    tagsText={tagsToString(resourceCardData.tags)}

    onClick={() => {
      openResourceModal(resourceCardData);
    }}

    onMouseOver={() => {
      setBackGroundColor(HOVER_BACKGROUND);
    }}

    onMouseLeave={() => {
      setBackGroundColor(DEFAULT_BACKGROUND);
    }}

    {...props} />;
}

const ResourceCard = React.forwardRef(ResourceCard_);
export default ResourceCard;
