// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import { useState } from "react";
import {
  PlasmicSubmitAProduct,
  DefaultSubmitAProductProps
} from "./plasmic/idea_hub/PlasmicSubmitAProduct";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { ToastContainer, toast } from 'react-toastify';
import * as EmailValidator from 'email-validator';

import "./SubmitAProduct.css";
import { sendNewProductFormToServer } from "../services/firebase_functions";

// Your component props start with props for variants and slots you defined
// in Plasmic, but you can add more here, like event handlers that you can
// attach to named nodes in your component.
//
// If you don't want to expose certain variants or slots as a prop, you can use
// Omit to hide them:
//
// interface SubmitAProductProps extends Omit<DefaultSubmitAProductProps, "hideProps1"|"hideProp2"> {
//   // etc.
// }
//
// You can also stop extending from DefaultSubmitAProductProps altogether and have
// total control over the props for your component.
export interface SubmitAProductProps extends DefaultSubmitAProductProps { }

function SubmitAProduct_(
  props: SubmitAProductProps,
  ref: HTMLElementRefOf<"div">
) {
  // Use PlasmicSubmitAProduct to render this component as it was
  // designed in Plasmic, by activating the appropriate variants,
  // attaching the appropriate event handlers, etc.  You
  // can also install whatever React hooks you need here to manage state or
  // fetch data.
  //
  // Props you can pass into PlasmicSubmitAProduct are:
  // 1. Variants you want to activate,
  // 2. Contents for slots you want to fill,
  // 3. Overrides for any named node in the component to attach behavior and data,
  // 4. Props to set on the root node.
  //
  // By default, we are just piping all SubmitAProductProps here, but feel free
  // to do whatever works for you.

  const [nameOfProductText, setNameOfProductText] = useState("");
  const [emailText, setEmailText] = useState("");
  const [urlOfProductText, setUrlOfProductText] = useState("");
  const [keyFeaturesText, setKeyFeaturesText] = useState("");
  const [pricingText, setPricingText] = useState("");
  const [socialsText, setSocialsText] = useState("");

  const [nameOfProductClass, setNameOfProductClass] = useState("");
  const [urlOfProductClass, setUrlOfProductClass] = useState("");
  const [keyFeaturesClass, setKeyFeaturesClass] = useState("");
  const [emailClass, setEmailClass] = useState("");
  const [pricingClass, setPricingClass] = useState("");

  function resetAllClasses() {
    setNameOfProductClass("");
    setUrlOfProductClass("");
    setKeyFeaturesClass("");
    setEmailClass("");
    setPricingClass("");
  }

  return <PlasmicSubmitAProduct root={{ ref }}

    nameOfProductTextBox={{
      className: nameOfProductClass,

      onChange(event) {
        setNameOfProductText(event.target.value);
        setNameOfProductClass("");
      }
    }}

    urlOfProductTextBox={{
      className: urlOfProductClass,

      onChange(event) {
        setUrlOfProductText(event.target.value);
        setUrlOfProductClass("");
      }
    }}

    keyFeaturesTextBox={{
      className: keyFeaturesClass,

      onChange(event) {
        setKeyFeaturesText(event.target.value);
        setKeyFeaturesClass("");
      }
    }}

    emailTextBox={{
      className: emailClass,

      onChange(event) {
        setEmailText(event.target.value);
        setEmailClass("");
      }
    }}

    pricingTextBox={{
      className: pricingClass,

      onChange(event) {
        setPricingText(event.target.value);
        setPricingClass("");
      }
    }}

    socialsTextBox={{
      onChange(event) {
        setSocialsText(event.target.value);
      }
    }}

    toastPlaceHolder={< ToastContainer />}

    submitButton={{
      onClick() {

        if (!nameOfProductText) {
          setNameOfProductClass("invalid");
          return toast.error("Please input a name for the product.")
        };

        if (!urlOfProductText) {
          setUrlOfProductClass("invalid");
          return toast.error("Please input a url for the product.")
        };

        if (!keyFeaturesText) {
          setKeyFeaturesClass("invalid");
          return toast.error("Please input some key features for the product.")
        };

        if (!EmailValidator.validate(emailText)) {
          setEmailClass("invalid");
          return toast.error("Please make sure your email is valid.")
        };

        if (!pricingText) {
          setPricingClass("invalid");
          return toast.error("Please input some pricing information for the product.")
        };

        toast.promise(sendNewProductFormToServer(nameOfProductText, urlOfProductText, keyFeaturesText, emailText, pricingText, socialsText), {
          pending: "Please wait...",
          success: "Thanks! We'll get back to you ASAP",
          error: "Sorry, Something went wrong. Please Try again"
        })

      }
    }}

    {...props} />;
}

const SubmitAProduct = React.forwardRef(SubmitAProduct_);
export default SubmitAProduct;
