// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import {
  PlasmicExplorePage,
  DefaultExplorePageProps
} from "./plasmic/idea_hub/PlasmicExplorePage";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import ResourceCard from "./ResourceCard";
import { ResourceCardModel, getBrandmarkResourceCardData, getAllResources } from "../model";
import ResourceModal2 from "./ResourceModal2";
import ButtonLinkSecondary from "./ButtonLinkSecondary";
import ButtonLinkPrimary from "./ButtonLinkPrimary";
import { logOpenResourceEvent, logOpenTagEvent } from "../services/firebase_functions";
import { TextField } from '@mui/material';
import { Autocomplete } from '@mui/material';

import "./ExplorePage.css"
import { useSearchParams } from "react-router-dom";
import { TypeAnimation } from "react-type-animation";

const CATEGORY_ALL = "All Categories";

const SORT_POPULAR = "Popular";
const SORT_RECENT = "Recent"

// Your component props start with props for variants and slots you defined
// in Plasmic, but you can add more here, like event handlers that you can
// attach to named nodes in your component.
//
// If you don't want to expose certain variants or slots as a prop, you can use
// Omit to hide them:
//
// interface ExplorePageProps extends Omit<DefaultExplorePageProps, "hideProps1"|"hideProp2"> {
//   // etc.
// }
//
// You can also stop extending from DefaultExplorePageProps altogether and have
// total control over the props for your component.
export interface ExplorePageProps extends DefaultExplorePageProps { }

function ExplorePage_(props: ExplorePageProps, ref: HTMLElementRefOf<"div">) {
  // Use PlasmicExplorePage to render this component as it was
  // designed in Plasmic, by activating the appropriate variants,
  // attaching the appropriate event handlers, etc.  You
  // can also install whatever React hooks you need here to manage state or
  // fetch data.
  //
  // Props you can pass into PlasmicExplorePage are:
  // 1. Variants you want to activate,
  // 2. Contents for slots you want to fill,
  // 3. Overrides for any named node in the component to attach behavior and data,
  // 4. Props to set on the root node.
  //
  // By default, we are just piping all ExplorePageProps here, but feel free
  // to do whatever works for you.

  const [resourceList, setResourceList] = React.useState(getAllResources());

  const [resourceModalOpen, setResourceModalOpen] = React.useState(false);

  const [currentResourceModal, setCurrentResourceModal] = React.useState(getBrandmarkResourceCardData());

  const [tagsList, setCategoriesList] = React.useState(createCategoresListFromTags(resourceList));

  const [selectedTags, setSelectedTags] = React.useState([CATEGORY_ALL]);

  const [selectedSort, setSelectedSort] = React.useState(SORT_POPULAR);

  const [searchParams, setSearchParams] = useSearchParams();
  const [ready, setReady] = React.useState(false);

  function setSelectedTagsAndLogEvent(selectedTags: string[]) {
    logOpenTagEvent(selectedTags);
    setSelectedTags(selectedTags);
  }

  React.useEffect(() => {
    if (ready) return;

    const selectedTags = searchParams.getAll('tags');
    setSelectedTagsAndLogEvent(selectedTags);

    setReady(true);

  }, [ready, searchParams]);

  function openResourceModal(resourceCardData: ResourceCardModel) {
    setCurrentResourceModal(resourceCardData);
    setResourceModalOpen(true);
    logOpenResourceEvent(resourceCardData.title);
  }

  function createCategoresListFromTags(resourceList: ResourceCardModel[]): string[] {
    let final_array = [CATEGORY_ALL]
    let all_tags: Set<string> = new Set();

    resourceList.forEach(resource => {
      resource.tags.forEach(tag => {
        all_tags.add(tag);
      });
    });

    return final_array.concat(Array.from(all_tags));
  }

  function filterResourceList(tagsList: string[], resourceList: ResourceCardModel[]): ResourceCardModel[] {
    return resourceList.filter((resource) => {

      if (tagsList.length == 0) {
        return true;
      }

      for (var tag of tagsList) {
        if (tag == CATEGORY_ALL) {
          return true;
        }

        if (resource.tags.includes(tag)) {
          return true
        }

        const searchable_text = resource.title + resource.bodyText + resource.shortText;
        if (searchable_text.includes(tag)) {
          return true
        }
      }

    });
  }

  const handleTagChange = (event: any, newValue: any) => {
    setSelectedTagsAndLogEvent(newValue);
    setSearchParams({ tags: newValue })
  };

  return <PlasmicExplorePage explorePage={{ ref }}

    subtitleText={<TypeAnimation className="typedHero"
      sequence={[
        'The ultimate app for founders to quickly find handpicked products.',
        1000,
        'The ultimate app for founders to quickly find handpicked resources.',
        1000,
        'The ultimate app for founders to quickly find top-notch products.',
        1000,
        'The ultimate app for founders to quickly find top-notch resources.',
        1000
      ]}
      wrapper="span"
      speed={20}
      repeat={Infinity}
    />}

    resourcesFilterDropdownMenu={{
      options: [SORT_POPULAR, SORT_RECENT],
      selectedContent: selectedSort,
      onChange: (newSort) => {
        if (newSort != null) setSelectedSort(newSort!);
      }
    }}

    selectTagsInput={
      <div style={{ "width": "100%" }} className="searchBox">
        <Autocomplete
          multiple
          id="tags-search"
          options={tagsList}
          value={selectedTags}
          onChange={handleTagChange}
          freeSolo
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder="Select or enter tags"
            />
          )}
        />
      </div>
    }

    resourceList={{
      children: filterResourceList(selectedTags, resourceList).map((resource) => <ResourceCard resourceCardData={resource} openResourceModal={openResourceModal} />)
    }}

    resourceInfoModal={{
      open: resourceModalOpen,
      onCancel: () => {
        setResourceModalOpen(false);
      },
      title: "",
      cancelButtonProps: { style: { "display": "none" } },
      okButtonProps: { style: { "display": "none" } },
      children: <ResourceModal2 resourceCardData={currentResourceModal} />
    }}

    {...props} />;
}

const ExplorePage = React.forwardRef(ExplorePage_);
export default ExplorePage;
