// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type TwitterSvgrepoComsvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function TwitterSvgrepoComsvgIcon(props: TwitterSvgrepoComsvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 24 24"}
      fill={"none"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M14.849 5.658a3.086 3.086 0 00-3.027 3.693 1 1 0 01-1.103 1.188 11.167 11.167 0 01-5.834-2.544c.031.602.24 1.242.693 1.912l.679 1.004-1.115.475-.215.092c.16.183.352.37.567.555a8.22 8.22 0 00.91.676l.011.007h.001l1.548.939-1.62.81c-.08.04-.16.076-.24.108a5.34 5.34 0 001.142.726l1.39.657-1.164 1.004c-.635.548-1.274.957-2.103 1.173A8.748 8.748 0 009.5 19.16c4.806 0 8.676-3.835 8.676-8.534v-.52l.425-.299c.664-.466 1.133-1.096 1.469-1.817h-2.16l-.272-.57a3.086 3.086 0 00-2.788-1.762zm-11.28 7.3a1 1 0 00-.237.797c.113.801.592 1.491 1.122 2.018.144.143.298.28.46.411l-.075.02c-.481.12-1.13.145-2.211.04a1 1 0 00-.813 1.694 10.716 10.716 0 007.684 3.222c5.713 0 10.399-4.436 10.664-10.034 1.29-1.083 1.935-2.55 2.283-3.883a1 1 0 00-.968-1.253h-2.353A5.086 5.086 0 009.78 8.329a9.214 9.214 0 01-4.721-2.975 1 1 0 00-1.673.23C2.78 6.906 2.664 8.43 3.34 9.98l-.234.1a1 1 0 00-.57 1.193c.186.652.604 1.228 1.032 1.685z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default TwitterSvgrepoComsvgIcon;
/* prettier-ignore-end */
