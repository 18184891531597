// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyB_V1_PpiMnHYnll2CCIFNyWbT8rkOTeNI",
    authDomain: "ideahub-b5d59.firebaseapp.com",
    projectId: "ideahub-b5d59",
    storageBucket: "ideahub-b5d59.appspot.com",
    messagingSenderId: "396771706622",
    appId: "1:396771706622:web:3648e5a2fd3cc8bd8507af",
    measurementId: "G-YD1W61H6LM"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);