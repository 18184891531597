// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import {
  PlasmicNewsLetter,
  DefaultNewsLetterProps
} from "./plasmic/idea_hub/PlasmicNewsLetter";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { logSubscribeToNewsletterEvent, sendEmailToServer } from "../services/firebase_functions";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as EmailValidator from 'email-validator';

// Your component props start with props for variants and slots you defined
// in Plasmic, but you can add more here, like event handlers that you can
// attach to named nodes in your component.
//
// If you don't want to expose certain variants or slots as a prop, you can use
// Omit to hide them:
//
// interface NewsLetterProps extends Omit<DefaultNewsLetterProps, "hideProps1"|"hideProp2"> {
//   // etc.
// }
//
// You can also stop extending from DefaultNewsLetterProps altogether and have
// total control over the props for your component.
export interface NewsLetterProps extends DefaultNewsLetterProps { }

function NewsLetter_(props: NewsLetterProps, ref: HTMLElementRefOf<"div">) {
  // Use PlasmicNewsLetter to render this component as it was
  // designed in Plasmic, by activating the appropriate variants,
  // attaching the appropriate event handlers, etc.  You
  // can also install whatever React hooks you need here to manage state or
  // fetch data.
  //
  // Props you can pass into PlasmicNewsLetter are:
  // 1. Variants you want to activate,
  // 2. Contents for slots you want to fill,
  // 3. Overrides for any named node in the component to attach behavior and data,
  // 4. Props to set on the root node.
  //
  // By default, we are just piping all NewsLetterProps here, but feel free
  // to do whatever works for you.

  const [emailText, setEmailText] = React.useState("")

  return <PlasmicNewsLetter root={{ ref }}

    toastPlaceholder={
      <ToastContainer />
    }

    emailInput={{
      onChange(event) {
        setEmailText(event.target.value);
      }
    }}

    emailSendButton={{
      onClick: () => {
        if (!EmailValidator.validate(emailText)) {
          toast.error("Sorry, please correct your email.");
          return
        }
        toast.promise(sendEmailToServer(emailText),
          {
            pending: "Please wait...",
            success: "Thanks for Signing Up!",
            error: "Sorry, Something went wrong. Please Try again"
          });

        logSubscribeToNewsletterEvent();
      }
    }}

    {...props} />;
}

const NewsLetter = React.forwardRef(NewsLetter_);
export default NewsLetter;
