// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type AdsClicksvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function AdsClicksvgIcon(props: AdsClicksvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 20 20"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M9.546 14.783a4.994 4.994 0 01-4.758-4.991c0-2.759 2.241-5 5-5a4.994 4.994 0 014.991 4.758l-1.75-.525a3.332 3.332 0 10-4.008 4.008l.525 1.75zm8.575-4.991c0 .25-.008.5-.033.75l-1.642-.492c.008-.083.008-.175.008-.258a6.665 6.665 0 00-6.666-6.667A6.665 6.665 0 003.12 9.792a6.665 6.665 0 006.667 6.666c.083 0 .175 0 .258-.008l.492 1.642a8.336 8.336 0 01-9.083-8.3c0-4.6 3.733-8.334 8.333-8.334s8.333 3.734 8.333 8.334zm-3.142 3.55l3.142-1.05-8.333-2.5 2.5 8.333 1.05-3.142 3.558 3.559 1.65-1.65-3.567-3.55z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default AdsClicksvgIcon;
/* prettier-ignore-end */
