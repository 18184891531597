// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Icon6IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Icon6Icon(props: Icon6IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 16 17"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M7.637 12.327A3.996 3.996 0 013.83 8.333c0-2.206 1.793-4 4-4a3.996 3.996 0 013.993 3.807l-1.4-.42a2.666 2.666 0 10-3.207 3.207l.42 1.4zm6.86-3.994c0 .2-.007.4-.027.6l-1.313-.393c.006-.067.006-.14.006-.207A5.332 5.332 0 007.83 3a5.332 5.332 0 00-5.333 5.333 5.332 5.332 0 005.333 5.334c.067 0 .14 0 .207-.007l.393 1.313a6.67 6.67 0 01-7.267-6.64A6.67 6.67 0 017.83 1.667a6.67 6.67 0 016.667 6.666zm-2.514 2.84l2.514-.84-6.667-2 2 6.667.84-2.513 2.847 2.846 1.32-1.32-2.854-2.84z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Icon6Icon;
/* prettier-ignore-end */
