// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Icon2IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Icon2Icon(props: Icon2IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 20 20"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M14.167 5.833h-3.334V7.5h3.334c1.375 0 2.5 1.125 2.5 2.5s-1.125 2.5-2.5 2.5h-3.334v1.667h3.334a4.168 4.168 0 000-8.334zm-5 6.667H5.833a2.507 2.507 0 01-2.5-2.5c0-1.375 1.125-2.5 2.5-2.5h3.334V5.833H5.833a4.168 4.168 0 000 8.334h3.334V12.5zm-2.5-3.333h6.666v1.666H6.667V9.167z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Icon2Icon;
/* prettier-ignore-end */
